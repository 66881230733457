import $ from 'jquery'
import 'jquery-match-height'

export default class MatchHeight {
  init() {
    $(window).on('load', function() {
      if ($('.js-matchHeight-group')) {
        $('.js-matchHeight-group').each(function() {
          const $this = $(this)
  
          $this.find('.js-matchHeight-target').matchHeight()
  
          if ($this.find('.js-matchHeight-target-two').length) {
            $this.find('.js-matchHeight-target-two').matchHeight()
          }
  
          if ($this.find('.js-matchHeight-target-three').length) {
            $this.find('.js-matchHeight-target-three').matchHeight()
          }
        })
      }
    })
  }
}
