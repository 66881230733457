import $ from 'jquery'

export default class CommonAccordian {
  constructor(props) {
    this.accordians = document.querySelectorAll(props.target)
  }

  init() {
    if(this.accordians) {
      $(function () {
        $('.js-cmn-accordian').each(function () {
          if($(this).hasClass('is-active')){
            $(this).next().stop().show()
          }

          $(this).on('click', function (e) {
            e.preventDefault();
      
            if(!$(this).hasClass('is-active')){
              $(this).addClass('is-active')
              $(this).next().stop().slideDown()
            } else {
              $(this).removeClass('is-active');
              $(this).next().stop().slideUp()
            }
          })
        })
      })
    }
  }
}

