import $ from 'jquery'
import Screen from './screen'
import { isActive, isOpen, isShow, breakpointMD } from '../_variables'

export default class NavGlobal {
  init() {
    $(function () {
      const screen = new Screen()

      $(window).on('breakpointChange', function () {
        if ($(window).innerWidth() < breakpointMD) {
          $('.c-header').removeClass(isActive)
        }
      })

      $('.js-header-menu').on('click', function () {
        if (!$(this).hasClass(isShow)) {
          $(this).addClass(isShow)
          $('.header-nav').addClass(isOpen)
          $('.c-header').addClass(isActive)
          $('.js-header-menu .text, .js-btn-close .text').text('閉じる')
          $('.js-btn-close').addClass(isShow)
          screen.lock()
        } else {
          $(this).removeClass(isShow)
          $('.header-nav').removeClass(isOpen)
          $('.c-header').removeClass(isActive)
          $('.js-header-menu .text, .js-btn-close .text').text('メニュー')
          $('.js-btn-close').removeClass(isShow)
          screen.unlock()
        }
      })

      $('.js-btn-close').on('click', function () {
        $('.js-header-menu').removeClass(isShow)
        $('.header-nav').removeClass(isOpen)
        $('.c-header').removeClass(isActive)
        $('.js-header-menu .text, .js-btn-close .text').text('メニュー')
        $('.js-btn-close').removeClass(isShow)
        screen.unlock()
      })
    })
  }
}
