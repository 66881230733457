import $ from 'jquery'

export default class CommonForm {
  constructor(props) {
    this.elm = document.querySelector(props.target)
  }

  init() {
    if (this.elm) {
      $(function() {
        $('.c-form select, .c-input-selectbox select').change(function(){
          if($(this).val()!=''){
            $(this).addClass('selected');
          } else {
            $(this).removeClass('selected');
          }
        });
        $('.c-form select, .c-input-selectbox select').on('focus', function(){
          $(this).addClass('selected');
        });
        $('.c-form select, .c-input-selectbox select').on('blur', function(){
          if($(this).val()!=''){
            $(this).addClass('selected');
          } else {
            $(this).removeClass('selected');
          }
        });

        $("#c-input-checkbox-01[type='checkbox']").change(function() {
          var ischecked = $(this).is(":checked");

          if(ischecked) {
            $('.step-01 .c-box-contact-button button').removeAttr('disabled')
            $('.step-01 .c-btn-center').removeClass('-is-disable')
          } else {
            $('.step-01 .c-box-contact-button button').attr('disabled', 'disabled')
            $('.step-01 .c-btn-center').addClass('-is-disable')
          }
        });

        $('.step-02 input, .step-02 select, .step-02 textarea').attr('disabled', 'disabled')


        if($('.c-form.wpcf7-form').hasClass('step-02')) {
          $('.formErrorContent, .wpcf7c-elm-step1').hide();
          $('.wpcf7-text').each(function(){
            if($(this).val() == '') {
              $(this).data('placeholder', $(this).attr('placeholder'));
              $(this).attr('placeholder', '');
            }
            if($(this).closest('.wpcf7-form-control-wrap').find('.is_clone_data').length == 0){
              var formData = '<span class="is_clone_data">' + $(this).val() + '</span>';
              $(this).closest('.wpcf7-form-control-wrap').append(formData);
              $(this).hide();
            }
          });
          $('.wpcf7-date').each(function(){
            if($(this).val() == '') {
              $(this).data('placeholder', $(this).attr('placeholder'));
              $(this).attr('placeholder', '');
            }
            if($(this).closest('.wpcf7-form-control-wrap').find('.is_clone_data').length == 0){
              var formData = '<span class="is_clone_data">' + $(this).val() + '</span>';
              $(this).closest('.wpcf7-form-control-wrap').append(formData);
              $(this).hide();
            }
          });
          $('.wpcf7-number').each(function(){
            if($(this).val() == '') {
              $(this).data('placeholder', $(this).attr('placeholder'));
              $(this).attr('placeholder', '');
            }
            if($(this).closest('.wpcf7-form-control-wrap').find('.is_clone_data').length == 0){
              var formData = '<span class="is_clone_data">' + $(this).val() + '</span>';
              $(this).closest('.wpcf7-form-control-wrap').append(formData);
              $(this).hide();
            }
          });
          $('.wpcf7-textarea').each(function(){
            if($(this).val() == '') {
              $(this).data('placeholder', $(this).attr('placeholder'));
              $(this).attr('placeholder', '');
            }
            if($(this).closest('.wpcf7-form-control-wrap').find('.is_clone_data').length == 0){
              var str = $(this).val();
              var message = str.replace(/(?:\r\n|\r|\n)/g, '<br>');
              var formData = '<span class="is_clone_data">' + message + '</span>';
              $(this).closest('.wpcf7-form-control-wrap').append(formData);
              $(this).hide();
            }
          });
           $('.wpcf7-checkbox, .wpcf7-radio').find('.wpcf7-list-item').each(function(){

            if($(this).find('.wpcf7c-conf-hidden').length == 0){
              $(this).closest('.wpcf7-list-item').hide();
            } else {
              if($(this).closest('.wpcf7-form-control-wrap').find('.is_clone_data').length == 0){
                var formData = '<span class="is_clone_data">' + $(this).find('.wpcf7c-conf-hidden').val() + '</span>';
                $(this).closest('.wpcf7-form-control-wrap').append(formData);
                $(this).hide();
              }
            }
          });
          $('.wpcf7-select').each(function(){
            if($(this).next('.wpcf7c-conf-hidden').val() == ''){
              $(this).next('.wpcf7c-conf-hidden').next('.CaptionCont').hide();
            }
            if($(this).closest('.wpcf7-form-control-wrap').find('.is_clone_data').length == 0){
              var formData = '<span class="is_clone_data">' + $(this).val() + '</span>';
              $(this).closest('.wpcf7-form-control-wrap').append(formData);
              $(this).hide();
            }
          });
          $('.wpcf7-captchar').each(function(){
            if($(this).closest('.wpcf7-form-control-wrap').find('.is_clone_data').length == 0){
              var formData = '<span class="is_clone_data">' + $(this).val() + '</span>';
              $(this).closest('.wpcf7-form-control-wrap').append(formData);
              $(this).hide();
            }
          });
          $('.u-text-red').hide();
          // 
        }


  
      })
    }
  }
}



