import $ from 'jquery'
import Screen from './screen'
import { isActive, isOpen, isShow, breakpointMD } from '../_variables'

export default class CaseSearch {
  constructor(props) {
    this.elm = document.querySelectorAll(props.target)
  }

  init() {
    if (this.elm) {
      const activeClass = '-is-active';
      const hiddenClass = '-is-hidden';

      $(function() {

        if ($(window).innerWidth() < breakpointMD) {
          $('.js-search').addClass(hiddenClass);
          if($('.js-keyword-toggle').length > 0) {
            $('.js-keyword-toggle').removeClass(activeClass);
          }
        }

        $('.js-search .item input').on('click', (e) => {
          $('.js-search .item input').removeClass(activeClass)
          $(this).addClass(activeClass)
        });

        $('.js-keyword-toggle').on('click', (e) => {

          if($('.js-search').hasClass(hiddenClass)) {
            $('.js-search').slideDown().removeClass(hiddenClass);
            $('.js-keyword-toggle').addClass(activeClass)
          } else {
            $('.js-search').slideUp().addClass(hiddenClass);
            $('.js-keyword-toggle').removeClass(activeClass);
          }
        });

        $(window).on('breakpointChange', function () {
          if ($(window).innerWidth() > breakpointMD) {
            $('.js-search').show().removeClass(hiddenClass).removeAttr( 'style' );
            $('.js-keyword-toggle').removeClass(activeClass);
          } else {
            $('.js-search').addClass(hiddenClass);
          }
        })

      });
    }
  }
}
