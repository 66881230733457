import $ from 'jquery'
const ImageCheck = () => {
  window.addEventListener('load', function() {
    $(".js-image-check").each(function(){
      const $this = $(this);
      if ($this.width() > $this.height()) {
          $this.parent().addClass("is-horizontal");
      } else {
        $this.parent().addClass("is-vertical");
      }
    });
  });
};
export default ImageCheck;
