export const isActive = 'is-active'
export const isShow = 'is-show'
export const isAnimate = 'is-animate'
export const isHide = 'is-hide'
export const isOpen = 'is-open'
export const isClose = 'is-close'
export const isLocked = 'is-locked'
export const isScroll = 'is-scroll'
export const breakpointMD = 897
export const animateFade = {
  fadeInDown:'fadeInDown'
}