import $ from "jquery";
import Pagetop from "./modules/pagetop";
import HeaderScroll from "./modules/headerScroll";
import NavGlobal from "./modules/navGlobal";
import PageTopScroll from "./modules/pagetopScroll";
import gnaviCurrent from "./modules/gnaviCurrent";
import DeviceCheck from "./modules/DeviceCheck";
import AnchorLink from "./modules/anchorLink";
import MatchHeight from "./modules/match_height";
import Screen from "./modules/screen";
import FadeScroll from './modules/fadeScroll';
import CommonAccordian from './modules/accordian'
import CaseSearch from './modules/caseSearch'
import WysiwygImg from './modules/wysiwygImg'
import CommonForm from './modules/form'
import FocusVisible from './modules/focus'
import imageCheck from "./modules/imageCheck";

/* ----------------------------------------------------------
  init
---------------------------------------------------------- */
const pagetop = new Pagetop({
  target: ".js-pagetop",
});

const anchorLink = new AnchorLink({
  target: ".js-anchor",
});

const matchHeight = new MatchHeight({
  target: ".js-matchHeight-group",
});

const pagetopscroll = new PageTopScroll();

const deviceCheck = new DeviceCheck({
  target: "html",
});

const headerscroll = new HeaderScroll({
  target: ".js-header-scroll",
});

const fadeScroll = new FadeScroll({
  target: '.js-fade-scroll'
});

const navGlobal = new NavGlobal();

const wysiwygImg = new WysiwygImg();

const commonAccordion = new CommonAccordian({
  target: '.js-cmn-accordian'
});

const caseSearch = new CaseSearch({
  target: '.js-search .item'
});

const commonForm = new CommonForm({
  target: '.c-form'
});

const focusVisible = new FocusVisible();

pagetop.init();
anchorLink.init();
headerscroll.init();
navGlobal.init();
pagetopscroll.init();
wysiwygImg.init();
deviceCheck.init();
fadeScroll.init();
gnaviCurrent();
commonAccordion.init();
matchHeight.init();
caseSearch.init();
commonForm.init();
focusVisible.init();
imageCheck();

/* ----------------------------------------------------------
  Screen
---------------------------------------------------------- */
const screen = new Screen();
$(window).on("resize", screen.size());
$(window).on("breakpointChange");